<template>
  <main>
    <StatusPage>
      <template #icon><IconMoodSadBrand class="icon" /></template>
      <template #title>{{ error.message }}</template>
      <template v-if="config.public.env === 'development' && error.stack" #desc><div v-html="error.stack" /></template>
      <template #actions>
        <BaseButton @click="handleError" color-primary>
          <template v-if="error.statusCode === 401">Авторизоваться</template>
          <template v-else><IconArrowLeft />На главную</template>
        </BaseButton>
      </template>
    </StatusPage>
  </main>
</template>

<script setup>
import { IconArrowLeft } from '@tabler/icons-vue'

const props = defineProps({
  error: Object
})

const config = useRuntimeConfig()
useTheme()
useHead({
  title: props.error.message
})

const handleError = () => clearError( { redirect: props.error.statusCode === 401 ? '/auth' : '/' })
</script>

<style scoped lang="scss">
main {
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
